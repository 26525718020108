import React from 'react';
import About from '../About/About';
import Badges from '../Badges';
import Flags from '../Flags';
import Contact from '../Contact/Contact';
import LinkGroup from '../LinkGroup/LinkGroup';
import FooterLogo from '../FooterLogo';
import PaymentIcons from '../PaymentIcons/PaymentIcons';
import useChildren from '../../../../hooks/useChildren';
import Styled from './Style';
import TrustpilotBlock from '../TrustpilotBlock/TrustpilotBlock';

const FooterContent = props => {
  const cleanedProps = useChildren(props);

  const badges = cleanedProps['badge'];
  const paymentIcons = cleanedProps['paymentIcon'];
  const linkGroupOneItems = cleanedProps['linkGroupOneItem'];
  const linkGroupTwoItems = cleanedProps['linkGroupTwoItem'];
  const flags = cleanedProps['flags'];

  return (
    <>
      <TrustpilotBlock />
      <Styled.Wrapper>
        <Badges badges={badges} />
        <Styled.WrapFooterNav>
          <Styled.LogoAndContactWrapper>
            <FooterLogo />
            <Flags flags={flags} />
            <Contact email={cleanedProps.email} phone={cleanedProps.phone} />
          </Styled.LogoAndContactWrapper>
          <About
            description={cleanedProps.aboutUsDescription}
            linkLabel={cleanedProps.aboutLinkLabel}
            linkUrl={cleanedProps.aboutLinkUrl}
            title={cleanedProps.aboutUsTitle}
          />
          {linkGroupOneItems && (
            <LinkGroup
              links={linkGroupOneItems}
              title={cleanedProps.linkGroupOneTitle}
            />
          )}
          {linkGroupTwoItems && (
            <LinkGroup
              links={linkGroupTwoItems}
              title={cleanedProps.linkGroupTwoTitle}
            />
          )}
        </Styled.WrapFooterNav>
        <PaymentIcons
          infoText={cleanedProps.paymentIconsText}
          icons={paymentIcons}
        />
      </Styled.Wrapper>
    </>
  );
};

export default FooterContent;
