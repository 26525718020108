import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import { usePrice } from '@jetshop/core/hooks/usePrice';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { css, cx } from 'linaria';
import React, { useMemo, useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from './Theme';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import VariantSelector from './VariantSelector/VariantSelector';
import StockStatusIndicator from '../pages/ProductPage/StockStatus/StockStatusIndicator';
import Favourite from './ProductList/Favourite';
import { AddToCart } from './ProductList/AddToCart';
import ProductDiscountBadge from './ProductDiscountBadge/ProductDiscountBadge';
import { toRem } from '../helpers';
import Price from './Price';
import Heading from './ui/Heading';
import useCheckChannelCountry from '../hooks/useCheckChannelCountry';
import useDynamicPriceWithHistory from '../pages/ProductPage/AddToCart/useDynamicPriceWithHistory';

const wrapperStyling = css`
  font-size: 1rem;
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background: ${theme.colorScheme.white};
  border: 1px solid ${theme.colorScheme.lightgrey};

  a {
    text-decoration: none;
    color: inherit;
  }
  a::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  .product-card-detail {
    background: white;
    padding: 1rem;
    line-height: 1.35;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    h3 {
      font-weight: 600;
      font-size: 1em;
    }

    h4 {
      font-weight: normal;
      font-size: 1em;
      color: ${theme.colorScheme.mediumgrey};
    }
  }
  .package-badge {
    background: black;
    color: white;
    font-size: 14px;
    padding: 3px 6px;
  }
  .price-package-wrapper {
    display: flex;
    align-items: flex-end;
    .package-price-label {
      font-size: 12px;
      color: grey;
      margin-left: 5px;
    }
  }
  .product-excerpt {
    font-size: 12px;
    margin-top: 0.15rem;
  }
  .product-card-footer {
    margin-top: 1em;
  }
  .product-card-price-cart-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    text-align: left;
  }
  .product-card-addtocart {
    z-index: 2;
    position: relative;
  }
  .discount-badge {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .badge-top-right {
    top: 3em;
  }
  .product-card-price {
    font-size: 16px;
    font-weight: bold;
    margin-right: 0.25rem;
    white-space: nowrap;
    line-height: 1.2;
    display: flex;
    flex-direction: column-reverse;
  }
  .new-price {
    color: ${theme.colorScheme.red};
  }
  .old-price {
    font-size: ${toRem(12)};
    font-weight: normal;
    text-decoration: line-through;
    color: ${theme.colorScheme.mediumgrey};
  }
  .product-card-incvat {
    display: none;
    font-size: ${toRem(10)};
    font-weight: normal;
    ${theme.below.sm} {
      display: none;
    }
  }
  .variant-selector,
  .children {
    z-index: 2;
    font-size: ${toRem(14)};
  }
`;

const FavouriteButtonWrapper = styled('div')`
  right: 0;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0.875rem 0 0;
  z-index: 2;
`;

const PreviousPriceContainer = styled('div')`
  display: flex;
  flex-direction: column;
  color: ${theme.colorScheme.mediumgrey};
  font-size: ${toRem(12)};
  font-weight: normal;
`;

const PriceContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const HeadingTag = styled('div')`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 1em;
  position: relative;
  font-style: normal;
  line-height: 1.48em;
  letter-spacing: 0.01em;
  margin-bottom: 0.75em;
  list-style: none;
`;

export function ProductCard({
  product,
  className,
  notificationId,
  imageAspect = '1:1',
  imageSizes = [1 / 2, 1 / 2, 1 / 2, 1 / 3, 1 / 4, 260],
  forwardRef,
  as = 'li',
  children,
  loadImageEagerly = false,
  hideExcerpt,
  ...linkProps
}) {
  let initialVariant = product.variant;
  // If no specific variation is linked, find the cheapest in stock
  if (!initialVariant && product.variants?.values?.length) {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    const variantsInStock = product.variants.values
      .filter(({ stockStatus }) => stockStatus.buyable)
      .sort((a, b) => {
        // prioritize in stock variants stockStatus.stockDate
        const aStockDate = new Date(a.stockStatus?.stockDate || currentDate);
        const bStockDate = new Date(b.stockStatus?.stockDate || currentDate);
        if (
          aStockDate !== bStockDate &&
          (aStockDate > Date(currentDate) || bStockDate > Date(currentDate))
        ) {
          return aStockDate - bStockDate;
        }
        return a.price?.incVat - b.price?.incVat;
      });
    if (variantsInStock.length) {
      initialVariant = { values: variantsInStock[0].values };
    }
  }
  const [envImageShow, setEnvImageShow] = useState(true);

  const variantHandler = useProductVariants(product, {
    initialVariant,
    preselectCheapest: true
  });

  const { selectedVariant: selectedVariation } = variantHandler;
  const { formattedPricing, currencyLocale } = usePrice(
    selectedVariation?.price || product?.price || { price: undefined }
  );
  const stockStatus = useStockStatus(selectedVariation || product);
  let status = useMemo(() => {
    let status = stockStatus.status;
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    // Show yellow dot if product has expected deliveryDate later than now
    // When a product is in stock the date will (should) be reset
    if (status && new Date(stockStatus.stockDate) > currentDate) {
      status = 'soonInStock';
    }
    return status;
  }, [stockStatus]);

  const hasImages = product.images && product.images.length > 0;
  const Tag = as;
  let badges = [...product.badges];
  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });
  const excerptLength = 80;
  // const cleanDescription =
  //   (product.description && product.description.replace(/<\/?[^>]*>/g, '')) ||
  //   '';
  const cleanDescription = product.shortDescription;
  const excerpt =
    cleanDescription.length > excerptLength
      ? `${cleanDescription.substr(0, excerptLength)}...`
      : cleanDescription;

  const {
    price,
    previousPrice,
    hasDiscount,
    latestPrice
  } = useDynamicPriceWithHistory(product, selectedVariation);
  const recommendedPrice = previousPrice;

  const showPreviousPrice =
    hasDiscount &&
    latestPrice?.incVat !== recommendedPrice?.incVat &&
    latestPrice?.incVat !== price?.incVat;

  const { isSE } = useCheckChannelCountry();

  const envImage = product?.customFields?.find(
    ({ key }) => key === 'miljobild'
  );

  return (
    <Tag
      className={cx('product-card', wrapperStyling, className)}
      data-testid="product"
      data-product-card
      ref={forwardRef}
      onMouseOver={e => setEnvImageShow(false)}
      onMouseOut={e => setEnvImageShow(true)}
    >
      <FavouriteButtonWrapper>
        <Favourite product={product} variant={selectedVariation} />
      </FavouriteButtonWrapper>
      <ProductDiscountBadge
        className="discount-badge"
        product={product}
        variant={selectedVariation}
        size="sm"
      />
      {envImage && envImageShow ? (
        <Image
          sizes={imageSizes}
          aspect={imageAspect}
          src={envImage.stringValue}
          badges={<Badges badges={badges} />}
          critical={loadImageEagerly}
        />
      ) : hasImages && product.images.length ? (
        selectedVariation?.images.length ? (
          <Image
            sizes={imageSizes}
            aspect={imageAspect}
            alt={selectedVariation.images[0].alt}
            src={selectedVariation.images[0].url}
            modifiedDate={selectedVariation.images[0].modifiedDate}
            badges={<Badges badges={badges} />}
            critical={loadImageEagerly}
          />
        ) : (
          <Image
            sizes={imageSizes}
            aspect={imageAspect}
            alt={product.images[0].alt}
            src={product.images[0].url}
            modifiedDate={product.images[0].modifiedDate}
            badges={<Badges badges={badges} />}
            critical={loadImageEagerly}
          />
        )
      ) : (
        <Image src={theme.imageFallback} />
      )}
      <div
        className="product-card-detail"
        onMouseOver={e => setEnvImageShow(false)}
        onMouseOut={e => setEnvImageShow(true)}
      >
        <header>
          <ProductLink product={product} {...linkProps}>
            <HeadingTag>
              <h3>{product.name}</h3>
            </HeadingTag>
          </ProductLink>
          {product.subName && <h4 className="sub-name">{product.subName}</h4>}
          {!hideExcerpt && excerpt && (
            <p className="product-excerpt">{excerpt}</p>
          )}
        </header>
        <footer className="product-card-footer">
          <div
            className="variant-selector"
            onClick={e => setEnvImageShow(false)}
          >
            <VariantSelector
              size="small"
              inline
              product={product}
              variantHandler={variantHandler}
            />
          </div>
          {stockStatus && (
            <StockStatusIndicator status={status} text={stockStatus.text} />
          )}
          <div className="product-card-price-cart-wrapper">
            <div className="price-package-wrapper">
              {product.price && (
                <>
                  <div className="product-card-price">
                    {isSE ? (
                      <PreviousPriceContainer>
                        {showPreviousPrice && (
                          <PriceContainer>
                            {t('Previous price: ')}

                            <Price
                              countryCode={currencyLocale?.culture}
                              decoration="line-through"
                              price={
                                latestPrice?.incVat &&
                                Number(latestPrice?.incVat)
                              }
                            >
                              {formattedPricing.price}
                            </Price>
                          </PriceContainer>
                        )}
                        {hasDiscount && (
                          <PriceContainer>
                            {t('Rec. price: ')}

                            <Price
                              countryCode={currencyLocale?.culture}
                              price={
                                recommendedPrice?.incVat &&
                                Number(recommendedPrice?.incVat)
                              }
                            >
                              {formattedPricing.price}
                            </Price>
                          </PriceContainer>
                        )}
                      </PreviousPriceContainer>
                    ) : (
                      hasDiscount && (
                        <PreviousPriceContainer>
                          <PriceContainer>
                            <Price
                              className="old-price"
                              countryCode={currencyLocale?.culture}
                              price={
                                previousPrice?.incVat &&
                                Number(previousPrice?.incVat)
                              }
                            >
                              {formattedPricing.price}
                            </Price>
                          </PriceContainer>
                        </PreviousPriceContainer>
                      )
                    )}

                    <Price
                      className={hasDiscount && 'new-price'}
                      price={price?.incVat && Number(price?.incVat)}
                      countryCode={currencyLocale.culture}
                    >
                      {formattedPricing.price}
                    </Price>
                  </div>
                  <span className="product-card-incvat">{t('inc. VAT')}</span>
                </>
              )}
            </div>
            <AddToCart
              notificationId={notificationId}
              className="product-card-addtocart"
              variant={selectedVariation}
              product={product}
            />
          </div>
        </footer>
      </div>
      {children ? <div className="children">{children}</div> : null}
    </Tag>
  );
}
