import React from 'react';
import { styled } from 'linaria/react';
import { toRem } from '../../../helpers';
import { theme } from '../../Theme';
import BrandLogo from '../BrandLogo';

const LogoWrapper = styled('div')`
  width: ${toRem(90)};
  max-width: ${toRem(90)};
  height: auto;
  margin-bottom: ${toRem(8)};

  ${theme.above.lg} {
    margin-bottom: ${toRem(10)};
  }
  svg {
    max-width: ${toRem(90)};
    height: auto;
  }
`;

const FooterLogo = () => {
  return (
    <LogoWrapper>
      <BrandLogo placement="footer" />
    </LogoWrapper>
  );
};

export default FooterLogo;
