import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { toRem } from '../../../helpers';

const FlagsWrapper = styled.div`
  img {
    width: 32px;
    height: 24px;
    margin-right: 6px;
  }

  margin-bottom: ${toRem(32)};

  ${theme.above.lg} {
    margin-bottom: ${toRem(80)};
  }
`;

const Flags = ({ flags }) => {
  if (!flags) return null;

  return (
    <FlagsWrapper>
      {flags.map(flag => {
        return (
          <a target={'_top'} href={flag.url}>
            <img key={flag.key} src={flag.flag} alt={'flag'} />
          </a>
        );
      })}
    </FlagsWrapper>
  );
};

export default Flags;
